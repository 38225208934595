import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Link, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";

const TableDeposit = (props) => {
     const classes = useStyles();
     return (
          <Grid xs={12} item className="table-btn">
               <TableContainer>
                    <Table className={classes.table}>
                         <TableHead>
                              <TableRow>
                                   {props.header.map((el, i) => (
                                        <TableCell key={i} align={el.align || "left"}>
                                             <Typography noWrap>{el.label}</Typography>
                                        </TableCell>
                                   ))}
                              </TableRow>
                         </TableHead>
                         <TableBody>
                              {props.body.map((el, i) => (
                                   <TableRow hover key={i} className={i % 2 !== 0 ? "bg-table" : ""}>
                                        <TableCell>
                                             <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                  {el.transaction_id}
                                             </Typography>
                                        </TableCell>
                                        <TableCell>
                                             <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                  {moment(el.created_at).format("DD/MM/YYYY HH:mm:ss")}
                                             </Typography>
                                        </TableCell>
                                        {/* <TableCell>
                                             <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                  {el.ip}
                                             </Typography>
                                        </TableCell> */}
                                        <TableCell>
                                             <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                  ฿ {el.money}
                                             </Typography>
                                        </TableCell>
                                        <TableCell>
                                             <Typography noWrap sx={{ fontSize: 16, color: "#707070" }}>
                                                  $ {(Math.round(el.receive * 100) / 100).toFixed(2)}
                                             </Typography>
                                        </TableCell>
                                        <TableCell>
                                             <Grid container alignContent="center">
                                                  <Button
                                                       variant="contained"
                                                       sx={{
                                                            maxWidth: 80,
                                                            width: "100%",
                                                            borderRadius: 2,
                                                            height: 30,
                                                            fontSize: 12,
                                                            backgroundColor: el.status === 1 ? "#5CE153" : el.status === 2 ? "#FF9747" : "#4589C6",
                                                       }}
                                                       onClick={() => props.onClickStatus(el.status, el.remark)}
                                                  >
                                                       {el.status === 1 ? "Confirm" : el.status === 2 ? "Pending" : "Reject"}
                                                  </Button>
                                             </Grid>
                                        </TableCell>
                                        <TableCell>
                                             {el.slip_path && (
                                                  <Link
                                                       component="button"
                                                       className={classes.viewStyle}
                                                       onClick={() => {
                                                            props.onClickViewSlip(el);
                                                       }}
                                                  >
                                                       View Slip
                                                  </Link>
                                             )}
                                        </TableCell>
                                   </TableRow>
                              ))}
                         </TableBody>
                    </Table>
               </TableContainer>
          </Grid>
     );
};

const useStyles = makeStyles({
     table: {
          minWidth: 320,
     },
     viewStyle: {
          // color: "#000"
          fontSize: 16,
     },
});

export default TableDeposit;
