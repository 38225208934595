import { Button, Checkbox, Container, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import '../../../css/index.scss';
const theme = createMuiTheme();

const Tap2FA = (props) => {
    const {
        language,
        profile,
        checked,
        handleChange,
        handleSubmit,
    } = props;

    const classes = useStyles();

    // console.log(profile);

    return (
        <Grid>
            <Grid mt={5} container justifyContent="center" alignItems="center" rowSpacing={2}>
                <Grid item xs={12}>
                    <Grid item container justifyContent="center" xs={12} marginTop={2}>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        disabled={profile.is_2fa}
                                    />
                                }
                                label={
                                    language === "th"
                                        ? "เปิดการใช้งานรหัสผ่านแบบสองชั้น"
                                        : "Enable two-factor authentication (2FA)."
                                }
                            />
                            <Typography sx={{ fontSize: { xs: 14, md: 16 }, textAlign: "justify", mt: 2, textIndent: "30px" }} color={profile.is_2fa ? "rgba(0, 0, 0, 0.38)" : "#676767"}>
                                {language === "th"
                                    ? "* เมื่อตั้งค่าการยืนยันตัวตนสองขั้นตอนแล้ว จะไม่สามารถปิดการใช้งานได้อีก"
                                    : "* Once two-factor authentication is enabled, it cannot be disabled."
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={5}>
                    <Container maxWidth="sm">
                        <Grid container textAlign="center" columnSpacing={1} rowSpacing={3} justifyContent="center">
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: "#4589C6" }}
                                    className="btn-style" sx={{ maxWidth: 260 }}
                                    onClick={handleSubmit}
                                    disabled={profile.is_2fa}
                                >
                                    {language === "th" ? "บันทึก" : "Svae"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Grid>
        </Grid >
    );
};

const useStyles = makeStyles(() => ({
    fontStyleDetail: {
        fontSize: 14,
        color: "#676767",
        textAlign: "justify",
        marginTop: 10,
        [theme.breakpoints.up("md")]: {
            fontSize: 16,
        },
        textIndent: "30px",
    },
}));

export default withRouter(Tap2FA);
