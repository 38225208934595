import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import DetailTaps from "./DetailTaps/DetailTaps";
import TapProfile from "./TapContent/TapProfile";
import TapDocuments from "./TapContent/TapDocuments";
import TapResetPassword from "./TapContent/TapResetPassword";
import { textFiled } from "../../functions/Static";
import { GET, POST, PROFILEAPI, UPLOADPROFILEAPI, UPDATEPROFILEAPI, UPDATEDOCUMENTAPI, RESETPASSWORD, UPDATEBANKAPI, UPDATECRYPTOAPI, UPDATE2FAAPI } from "service";
import swal from "sweetalert2";
import LoadingPage from "components/LoadingPage/LoadingPage";
import TapAccount from "./TapContent/TapAccount";
import TapCrypto from "./TapContent/TapCrypto";
import moment from "moment";
import Tap2FA from "./TapContent/Tap2FA";

export class Profile extends Component {
     constructor(props) {
          super(props);

          this.state = {
               profile: {},
               tap_value: 1,
               language_state: localStorage.getItem("language"),
               bank_check: false,
               inputValue: {
                    name: null,
                    lastname: null,
                    idcard: null,
                    location: null,
                    address: null,
                    phone: null,
                    dob: null,
                    bank_name: "เลือกธนาคาร",
                    bank_code: "เลือกธนาคาร",
                    crypto_name: "Crypto Type",
                    crypto_type: "Crypto Type",
                    address_usdt: null,
                    bank_name_other: null,
               },
               textfieldDetail: [
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Name",
                         typography_th: "ชื่อ",
                         placeholder: "Name",
                         placeholder_th: "ชื่อ",
                         name: "name",
                         disabled: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Lastname",
                         typography_th: "นามสกุล",
                         placeholder: "Lastname",
                         placeholder_th: "นามสกุล",
                         name: "lastname",
                         disabled: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "ID Card/Passport",
                         typography_th: "หมายเลขประจำตัว/พาสปอร์ต",
                         placeholder: "ID Card/Passport",
                         placeholder_th: "หมายเลขประจำตัว/พาสปอร์ต",
                         name: "idcard",
                         disabled: false,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Country",
                         typography_th: "ประเทศ",
                         placeholder: "Location",
                         placeholder_th: "ประเทศ",
                         name: "location",
                         disabled: false,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Address",
                         typography_th: "ที่อยู่",
                         placeholder: "Address",
                         placeholder_th: "ที่อยู่",
                         name: "address",
                         disabled: false,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Mobile Phone",
                         typography_th: "เบอร์โทรศัพท์",
                         placeholder: "080-00-0000",
                         placeholder_th: "เบอร์โทรศัพท์",
                         name: "phone",
                         type: "number",
                         disabled: false,
                    },
                    // {
                    //      xs: 12,
                    //      classNameGrid: "typography-profile pt-0",
                    //      classNameText: "textfield-profile mt-2",
                    //      typography: "Date of birth",
                    //      typography_th: "วัน/เดือน/ปี เกิด",
                    //      placeholder: "Date of birth",
                    //      placeholder_th: "วัน/เดือน/ปี เกิด",
                    //      name: "dob",
                    //      type: "date",
                    //      disabled: false,
                    // },
               ],
               upload_bookbank: [
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Bank",
                         typography_th: "ธนาคาร",
                         placeholder: "Bank",
                         placeholder_th: "ธนาคาร",
                         name: "bank_code",
                         selectname: "menuItem",
                         select: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Account Number",
                         typography_th: "หมายเลขบัญชี",
                         placeholder: "Account Number",
                         placeholder_th: "หมายเลขบัญชี",
                         name: "account_number",
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Account Name",
                         typography_th: "ชื่อบัญชี",
                         placeholder: "Account Name",
                         placeholder_th: "ชื่อบัญชี",
                         name: "account_name",
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Branch Name",
                         typography_th: "สาขา",
                         placeholder: "Branch Name",
                         placeholder_th: "สาขา",
                         name: "branch_name",
                    },
               ],
               upload_bookbank_other: [
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Bank",
                         typography_th: "ธนาคาร",
                         placeholder: "Bank",
                         placeholder_th: "ธนาคาร",
                         name: "bank_code",
                         selectname: "menuItem",
                         select: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Bank",
                         typography_th: "ธนาคาร",
                         placeholder: "Bank",
                         placeholder_th: "ธนาคาร",
                         name: "bank_name_other",
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Account Number",
                         typography_th: "หมายเลขบัญชี",
                         placeholder: "Account Number",
                         placeholder_th: "หมายเลขบัญชี",
                         name: "account_number",
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Account Name",
                         typography_th: "ชื่อบัญชี",
                         placeholder: "Account Name",
                         placeholder_th: "ชื่อบัญชี",
                         name: "account_name",
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Branch Name",
                         typography_th: "สาขา",
                         placeholder: "Branch Name",
                         placeholder_th: "สาขา",
                         name: "branch_name",
                    },
               ],
               textfield_crypto: [
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Crypto Type",
                         // typography_th: "Crypto Type",
                         placeholder: "Crypto Type",
                         // placeholder_th: "crypto_type",
                         name: "crypto_type",
                         selectname: "menuItem",
                         select: true,
                    },
                    {
                         xs: 12,
                         classNameGrid: "typography-profile pt-0",
                         classNameText: "textfield-profile mt-2",
                         typography: "Address USDT",
                         // typography_th: "Address USDT",
                         placeholder: "Address USDT",
                         // placeholder_th: "Address USDT",
                         name: "address_usdt",
                    },
               ],
               menuItem: [
                    { label: "เลือกธนาคาร", value: "เลือกธนาคาร", disabled: true },
                    { label: "ธนาคารกรุงเทพ", value: "BBL" },
                    { label: "ธนาคารกรุงไทย", value: "KTB" },
                    { label: "ธนาคารกรุงศรีอยุธยา", value: "BAY" },
                    { label: "ธนาคารกสิกรไทย", value: "KBANK" },
                    { label: "ธนาคารเกียรตินาคินภัทร", value: "KKP" },
                    { label: "ซิตี้แบงก์ประเทศไทย", value: "CITI" },
                    { label: "ธนาคารซีไอเอ็มบีไทย", value: "CIMBT" },
                    { label: "ธนาคารทหารไทยธนชาต", value: "TTB" },
                    { label: "ธนาคารไทยพาณิชย์", value: "SCB" },
                    { label: "ธนาคารทิสโก้", value: "TISCO" },
                    { label: "ธนาคารไทยเครดิตเพื่อรายย่อย", value: "TCD" },
                    { label: "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย", value: "SME" },
                    { label: "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย", value: "EXIM" },
                    { label: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", value: "BAAC" },
                    { label: "ธนาคารยูโอบี", value: "UOBT" },
                    { label: "ธนาคารอิสลามแห่งประเทศไทย", value: "ISBT" },
                    { label: "ธนาคารออมสิน", value: "GSB" },
                    { label: "ธนาคารอาคารสงเคราะห์", value: "GHB" },
                    { label: "ธนาคารแลนด์ แอนด์ เฮ้าส์", value: "LH" },
                    { label: "อื่นๆ (Other)", value: "OTHER" },
               ],
               menuCrypto: [
                    { label: "Crypto Type", value: "Crypto Type", disabled: true },
                    { label: "USDT TRC 20", value: "USDT TRC 20" },
                    { label: "USDT ERC 20", value: "USDT ERC 20" },
               ],
               checkInput: {},
               loading: false,
               checked2FA: false,
          };
     }

     componentDidMount() {
          this.getProfile();
     }

     async getProfile() {
          let { inputValue, textfieldDetail, upload_bookbank, textfield_crypto } = this.state;
          try {
               this.setState({ loading: true });
               let profile = await GET(PROFILEAPI);
               if (profile.success) {
                    this.setState({
                         profile: profile.result,
                         // checked2FA: profile.result.is_2fa
                    });
                    let result = profile.result;
                    inputValue.name = result.name || inputValue.name;
                    inputValue.lastname = result.lastname || inputValue.lastname;
                    inputValue.idcard = result.identity || inputValue.idcard;
                    inputValue.location = result.address || inputValue.location;
                    inputValue.address = result.address2 || inputValue.phone;
                    inputValue.phone = result.phone || inputValue.phone;
                    inputValue.dob = (result.dob ? new Date(result.dob) : null) || (inputValue.dob ? new Date(inputValue.dob) : null);
                    inputValue.account_name = result.account_name || inputValue.account_name;
                    inputValue.account_number = result.bank_account || inputValue.account_number;
                    inputValue.branch_name = result.branch_name || inputValue.branch_name;
                    inputValue.bank_name = result.bank_name || inputValue.bank_name;
                    inputValue.bank_code = result.bank_code || inputValue.bank_code;
                    inputValue.crypto_type = result.crypto_type || inputValue.crypto_type;
                    inputValue.address_usdt = result.address_usdt || inputValue.address_usdt;
                    if (result.bank_name && result.bank_code === null) {
                         inputValue.bank_name_other = result.bank_name || inputValue.bank_name;
                         inputValue.bank_code = "OTHER";
                         this.setState({ bank_check: true })
                    }
                    if (result.verify_doc === 1 || result.verify_doc === 4) {
                         textfieldDetail.map((el) => {
                              el.disabled = true;
                         });
                    }
                    if (result.verify_bank === 1 || result.verify_bank === 4) {
                         upload_bookbank.map((el) => {
                              el.disabled = true;
                         });
                    }
                    if ((inputValue.crypto_type !== 'Crypto Type') && inputValue.address_usdt) {
                         textfield_crypto.map((el) => {
                              el.disabled = true;
                         });
                    }
                    this.setState({ inputValue, textfieldDetail, upload_bookbank, textfield_crypto });
                    this.setState({ loading: false });
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "Warning",
                         text: profile.message,
                         icon: "warning",
                         showConfirmButton: true,
                    });
               }
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "Warning",
                    text: error,
                    icon: "warning",
                    showConfirmButton: true,
               });
          }
     }

     handleChange = (e) => {
          let { checkInput, inputValue, menuItem, menuCrypto } = this.state;
          let name = e.target.name;
          let value = e.target.value;
          checkInput[name] = {
               err: value ? false : true,
               label: "",
          };
          inputValue[name] = value;
          if (name === "bank_code") {
               let bank_name = menuItem.find((el) => el.value === value).label;
               inputValue["bank_name"] = bank_name;

               inputValue["bank_name_other"] = null;
               this.setState({ bank_check: false })

               if (bank_name === "อื่นๆ (Other)") {
                    this.setState({ bank_check: true })
               }
          }
          if (name === "crypto_type") {
               let crypto_name = menuCrypto.find((el) => el.value === value).label;
               inputValue["crypto_name"] = crypto_name;
          }
          if (name === "account_number") {
               // console.log(value);
               let account_number = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
               // console.log('account_number', account_number);
               inputValue["account_number"] = account_number;
          }
          this.setState({ inputValue, checkInput });
     };
     checkInput = () => {
          let state = this.state;
          let { checkInput, tap_value } = this.state;
          let Check = textFiled[tap_value === 1 ? "editProfile" : "resetPassword"].map((el) => el.name);
          Check.forEach((e) => {
               checkInput[e] = {
                    err: state.inputValue[e] ? false : true,
                    label: state.inputValue[e] ? "" : "",
               };
          });
          this.setState({ checkInput }, async () => {
               let { checkInput, language_state } = this.state;
               let input = Object.keys(checkInput).some((e) => checkInput[e].err);
               if (tap_value === 1) {
                    if (!input) {
                         this.setState({ checkInput: {} });
                         this.setDataProfile(state.inputValue);
                    } else {
                         window.location.assign('/dashboard');
                    }
               } else if (tap_value === 3) {
                    // console.log("tap_valueeeeeee", tap_value);
                    // console.log("input", input);
                    // console.log("checkInput", checkInput);

                    if (!input) {
                         // console.log("ifffff");
                         this.setState({ checkInput: {} });
                         this.setDataBank(state.inputValue);
                    }
               } else {
                    if (!input) {
                         this.setState({ checkInput: {} });
                         this.onClickResetPassword();
                    } else {
                         swal.fire({
                              title: "Warning",
                              text: `${language_state === "th" ? "กรุณากรอกข้อมูลให้ครบ" : "Please fill complete"}`,
                              icon: "warning",
                              showConfirmButton: true,
                         });
                    }
               }
          });
     };

     setDataProfile = async (data) => {
          let date_dob = moment(data.dob).format("YYYY-MM-DD");
          let dataSet = {
               // name: data.name,
               // lastname: data.lastname,
               identity: data.idcard,
               location: data.location,
               address: data.address,
               phone: data.phone,
               dob: date_dob,
          };
          const { language_state } = this.state;
          try {
               console.log(dataSet);
               this.setState({ loading: true });
               let updateProfile = await POST(UPDATEPROFILEAPI, dataSet);
               if (updateProfile.success) {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "Sucess",
                         text: `${language_state === "th" ? "อัพเดทข้อมูลสำเร็จ รอการยืนยัน" : "Update success, Wait approve"}`,
                         icon: "success",
                         showConfirmButton: true,
                    });
                    window.location.reload();
               } else {
                    this.setState({ loading: false });
                    swal.fire({ title: "Warning", text: updateProfile.message, icon: "warning", showConfirmButton: true });
               }
          } catch (e) {
               this.setState({ loading: false });
               swal.fire({ title: "Warning", text: e, icon: "warning", showConfirmButton: true });
          }
     };

     // setDataBank = async (data) => {
     //      console.log("setDataBank");
     //      let dataSet = {
     //           account_name: data.account_name,
     //           account_number: data.account_number,
     //           bank_code: data.bank_code,
     //           bank_name: data.bank_name,
     //           branch_name: data.branch_name,
     //      };
     //      const { language_state } = this.state;
     //      try {
     //           this.setState({ loading: true });
     //           let updateProfile = await POST(UPDATEBANKAPI, dataSet);
     //           if (updateProfile.success) {
     //                this.setState({ loading: false });
     //                swal.fire({
     //                     title: "Sucess",
     //                     text: `${language_state === "th" ? "อัพเดทข้อมูลสำเร็จ รอการยืนยัน" : "Update success, Wait approve"}`,
     //                     icon: "success",
     //                     timer: 1800,
     //                     showConfirmButton: false,
     //                });
     //                window.location.reload();
     //           } else {
     //                this.setState({ loading: false });
     //                swal.fire({ title: "Warning", text: updateProfile.message, icon: "warning", timer: 1800, showConfirmButton: false });
     //           }
     //      } catch (e) {
     //           this.setState({ loading: false });
     //           swal.fire({ title: "Warning", text: e, icon: "warning", timer: 1800, showConfirmButton: false });
     //      }
     // };

     submitImage = async (image, id, img_bookbank) => {
          const { account_name, account_number, bank_name, language_state, bank_code, branch_name } = this.state.inputValue;
          // if (bank_code !== "เลือกธนาคาร" && account_name && account_number && branch_name) {
          let form_data = new FormData();
          if (id === 2 && image[0] && image[1]) {
               form_data.append("document", image[0]);
               form_data.append("document", image[1]);
               // form_data.append("bank", img_bookbank);
               form_data.append("document_id", id);
               // form_data.append("bank_name", bank_name);
               // form_data.append("bank_code", bank_code);
               // form_data.append("account_name", account_name);
               // form_data.append("bank_account", account_number);
               // form_data.append("branch_name",branch_name);
          } else if (id !== 2 && image[0]) {
               form_data.append("document", image[0]);
               // form_data.append("bank", img_bookbank);
               form_data.append("document_id", id);
               // form_data.append("bank_name", bank_name);
               // form_data.append("bank_code", bank_code);
               // form_data.append("account_name", account_name);
               // form_data.append("bank_account", account_number);
               // form_data.append("branch_name",branch_name);
          } else {
               form_data = null;
          }
          if (form_data) {
               try {
                    this.setState({ loading: true });
                    let uploadProfileDoc = await POST(UPDATEDOCUMENTAPI, form_data, true);
                    if (uploadProfileDoc.success) {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "Success",
                              text: `${language_state === "th" ? "อัพเดทข้อมูลสำเร็จ รอการยืนยัน" : "Update success, Wait approve"}`,
                              icon: "success",
                              timer: 3500,
                              showConfirmButton: false,
                         });
                         // window.location.reload();
                         return true;
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "Warning", text: uploadProfileDoc.message, icon: "warning", showConfirmButton: true });
                         return false;
                    }
               } catch (error) {
                    this.setState({ loading: false });
                    swal.fire({ title: "Warning", text: error, icon: "warning", showConfirmButton: true });
                    return false;
               }
          } else {
               this.setState({ loading: false });
               swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณาอัพโหลดเอกสารให้ครบ" : "Please upload doc complete"}`,
                    icon: "warning",
                    showConfirmButton: true,
               });
          }
          // } else {
          //      this.setState({ loading: false });
          //      swal.fire({
          //           title: "Warning",
          //           text: `${language_state === "th" ? "กรุณากรอกข้อมูลให้ครบ" : "Please fill complete"}`,
          //           icon: "warning",
          //           timer: 1800,
          //           showConfirmButton: false,
          //      });
          // }
     };

     submitAccount = async (img_bookbank) => {
          const { bank_check } = this.state;
          const { account_name, account_number, bank_name, language_state, bank_code, branch_name, bank_name_other } = this.state.inputValue;
          let check_bookbank = bank_code !== "เลือกธนาคาร" && account_name && account_number && branch_name && img_bookbank;
          let check_bookbank_other = bank_code !== "เลือกธนาคาร" && account_name && account_number && branch_name && img_bookbank && bank_name_other;
          // console.log(bank_check);

          if (bank_check ? check_bookbank_other : check_bookbank) {
               let form_data = new FormData();

               form_data.append("bank", img_bookbank);
               form_data.append("bank_name", bank_check ? bank_name_other : bank_name);
               form_data.append("bank_code", bank_code);
               form_data.append("account_name", account_name);
               form_data.append("bank_account", account_number);
               form_data.append("branch_name", branch_name);

               if (form_data) {
                    try {
                         this.setState({ loading: true });
                         let uploadProfileDoc = await POST(UPDATEBANKAPI, form_data, true);
                         if (uploadProfileDoc.success) {
                              this.setState({ loading: false });
                              this.getProfile();
                              swal.fire({
                                   title: "Success",
                                   text: `${language_state === "th" ? "อัพเดทข้อมูลสำเร็จ รอการยืนยัน" : "Update success, Wait approve"}`,
                                   icon: "success",
                                   showConfirmButton: true,
                              });
                              // window.location.reload();
                              return true;
                         } else {
                              this.setState({ loading: false });
                              swal.fire({ title: "Warning", text: uploadProfileDoc.message, icon: "warning", showConfirmButton: true });
                              return false;
                         }
                    } catch (error) {
                         this.setState({ loading: false });
                         swal.fire({ title: "Warning", text: error, icon: "warning", showConfirmButton: true });
                         return false;
                    }
               } else {
                    this.setState({ loading: false });
                    swal.fire({
                         title: "Warning",
                         text: `${language_state === "th" ? "กรุณาอัพโหลดเอกสารให้ครบ" : "Please upload doc complete"}`,
                         icon: "warning",
                         showConfirmButton: true,
                    });
               }
          } else {
               this.setState({ loading: false });
               swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณากรอกข้อมูลให้ครบ" : "Please fill complete"}`,
                    icon: "warning",
                    showConfirmButton: true,
               });
          }
     };

     submitCrypto = async () => {
          const { crypto_type, address_usdt, language_state } = this.state.inputValue;
          if (crypto_type !== "Crypto Type" && address_usdt) {
               // console.log(crypto_type, address_usdt);
               try {
                    this.setState({ loading: true });
                    let res = await POST(UPDATECRYPTOAPI, { crypto_type, address_usdt });
                    if (res.success) {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "Success",
                              text: `${language_state === "th" ? "อัพเดทข้อมูลสำเร็จ" : "Update success"}`,
                              icon: "success",
                              showConfirmButton: true,
                         }).then((result) => {
                              if (result.isConfirmed) {
                                   // window.location.reload();
                                   this.getProfile();
                              }
                         });
                         // return true;
                    } else {
                         this.setState({ loading: false });
                         swal.fire({ title: "Warning", text: res.message, icon: "warning", showConfirmButton: true });
                         // return false;
                    }
               } catch (error) {
                    this.setState({ loading: false });
                    swal.fire({ title: "Warning", text: error, icon: "warning", showConfirmButton: true });
                    // return false;
               }
          } else {
               this.setState({ loading: false });
               swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณากรอกข้อมูลให้ครบ" : "Please fill complete"}`,
                    icon: "warning",
                    showConfirmButton: true,
               });
          }
     };

     uploadProfile = async (e) => {
          const { profile, language_state } = this.state;
          let file = e.target.files[0];

          if (e.target.files && e.target.files[0]) {
               if (file.size < 10485760) {
                    const reader = new FileReader();
                    reader.onload = () => {
                         if (reader.readyState === 2) {
                              profile.profile_image = reader.result;
                              this.setState({ profile: profile });
                         }
                    };
                    reader.readAsDataURL(e.target.files[0]);

                    try {
                         this.setState({ loading: true });
                         let form_data = new FormData();
                         form_data.append("profile_image", e.target.files[0]);
                         let uploadProfile = await POST(UPLOADPROFILEAPI, form_data, true);
                         if (uploadProfile.success) {
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "Success",
                                   text: `${language_state === "th" ? "อัพโหลดรูปภาพสำเร็จ" : "Upload image completed"}`,
                                   icon: "success",
                                   showConfirmButton: true,
                              });
                         } else {
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "Warning",
                                   text: uploadProfile.message,
                                   icon: "warning",
                                   showConfirmButton: true,
                              });
                         }
                    } catch (e) {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "Warning",
                              text: e,
                              icon: "warning",
                              showConfirmButton: true,
                         });
                    }
               } else {
                    swal.fire({
                         icon: "warning",
                         text: `อัฟโหลดไฟล์ได้ขนาดไม่เกิน 10 MB`,
                         showConfirmButton: true,
                    });
               }
          }

     };

     onClickResetPassword = async () => {
          const { old_password, new_password, repeat_password, language_state } = this.state.inputValue;
          if (old_password && new_password && repeat_password) {
               if (new_password.length < 6) {
                    swal.fire({
                         title: `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีอย่างน้อย 6 ตัวอักษรขึ้นไป" : "Your password needs a minimum of six characters"}`,
                         icon: "warning",
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีอย่างน้อย 6 ตัวอักษรขึ้นไป" : "Your password needs a minimum of six characters"}`,
                    });
               } else if (new_password.search(/[a-z]/) < 0) {
                    swal.fire({
                         title: `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์เล็ก" : "Your password needs a lower case letter"}`,
                         icon: "warning",
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์เล็ก" : "Your password needs a lower case letter"}`,
                    });
               } else if (new_password.search(/[A-Z]/) < 0) {
                    swal.fire({
                         title: `${language_state === "th" ? `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}` : "Warning"}`,
                         text: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์ใหญ่" : "Your password needs an uppser case letter"}`,
                         icon: "warning",
                         showConfirmButton: true,
                    });
                    this.setState({
                         error: true,
                         error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์ใหญ่" : "Your password needs an uppser case letter"}`,
                    });
               } else if (new_password.search(/[0-9]/) < 0) {
                    swal.fire({
                         title: `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                         text: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวเลข" : "Your password needs a number"}`,
                         icon: "warning",
                         showConfirmButton: true,
                    });
                    this.setState({ error: true, error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวเลข" : "Your password needs a number"}` });
               } else {
                    if (new_password === repeat_password) {
                         try {
                              this.setState({ loading: true });
                              let changePassword = await POST(RESETPASSWORD, { new_password, old_password });
                              if (changePassword.success) {
                                   this.setState({ loading: false });
                                   swal.fire({
                                        title: "Success",
                                        text: `${language_state === "th" ? "รีเซ็ตรหัสผ่านสำเร็จ" : "Reset password complete"}`,
                                        icon: "success",
                                        showConfirmButton: true,
                                   });
                                   window.location.reload();
                              } else {
                                   this.setState({ loading: false });
                                   swal.fire({ title: "Warning", text: changePassword.message, icon: "warning", showConfirmButton: true });
                              }
                         } catch (error) {
                              this.setState({ loading: false });
                              swal.fire({ title: "Warning", text: error, icon: "warning", showConfirmButton: true });
                         }
                    } else {
                         this.setState({ loading: false });
                         swal.fire({
                              title: "Warning",
                              text: `${language_state === "th" ? "กรุณากรอกรหัสผ่านให้ตรงกัน" : "Password not match"}`,
                              icon: "warning",
                              showConfirmButton: true,
                         });
                    }
               }
          } else {
               this.setState({ loading: false });
               swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "กรุณากรอกให้ครบ" : "Please fill complete"}`,
                    icon: "warning",
                    showConfirmButton: true,
               });
          }
     };

     submit2FA = async () => {
          const { language_state, checked2FA } = this.state;
          console.log('checked2FA', checked2FA);
          try {
               swal.fire({
                    text: `${language_state === "th" ? "คุณต้องการเปิดการใช้งานรหัสสองขั้นหรือไม่" : "Do you want to enable two-factor authentication?"}`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cancel",
               }).then(async (result) => {
                    if (result.isConfirmed) {
                         this.setState({ loading: true });
                         const payload = { is_2fa: checked2FA }
                         let res = await POST(UPDATE2FAAPI, { payload });
                         if (res.success) {
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "Success",
                                   text: `${language_state === "th" ? "เปิดการใช้งานสำเร็จ" : "Activation successful."}`,
                                   icon: "success",
                                   showConfirmButton: true,
                              }).then(async (result) => {
                                   if (result.isConfirmed) {
                                        // window.location.reload();
                                        this.getProfile();
                                   }
                              })
                         } else {
                              this.setState({ loading: false });
                              swal.fire({
                                   title: "Warning",
                                   text: res.message,
                                   icon: "warning",
                                   showConfirmButton: true,
                              });
                         }
                    }
               });
          } catch (error) {
               this.setState({ loading: false });
               swal.fire({
                    title: "Warning",
                    text: error,
                    icon: "warning",
                    showConfirmButton: true,
               });
          }
     };

     handleDob = (date) => {
          const { inputValue } = this.state;
          inputValue.dob = date;
          this.setState({ inputValue });
     };

     handleChange2FA = (event) => {
          this.setState({ checked2FA: event.target.checked });
     };

     render() {
          const { tap_value, inputValue, checkInput, profile, textfieldDetail, upload_bookbank, upload_bookbank_other, menuItem, loading, menuCrypto, textfield_crypto, bank_check, checked2FA } = this.state;
          const language = localStorage.getItem("language");
          return (
               <Container maxWidth="xl" sx={{ position: "relative" }}>
                    <LoadingPage open={loading} />
                    {/* <Grid sx={{ position: "inherit", top: -88 }}> */}
                    <Grid sx={{ mb: 10 }}>
                         <DetailTaps setTap={(e) => this.setState({ tap_value: e })} language={language} />
                    </Grid>
                    <Grid>
                         {tap_value === 1 ? (
                              <TapProfile
                                   language={language}
                                   profile={profile}
                                   data={textfieldDetail}
                                   inputValue={inputValue}
                                   checkInput={checkInput}
                                   handleChange={this.handleChange}
                                   handleSubmit={this.checkInput}
                                   uploadProfile={this.uploadProfile}
                                   handleDob={this.handleDob}
                              />
                         ) : tap_value === 2 ? (
                              <TapDocuments
                                   submitImage={this.submitImage}
                                   document_path={profile.document_path}
                                   language={language}
                                   profile={profile}
                                   handleChangeText={this.handleChange}
                                   inputValue={inputValue}
                                   checkInput={checkInput}
                                   handleSubmit={this.checkInput}
                                   // data={upload_bookbank}
                                   menuItem={menuItem}
                              />
                         ) : tap_value === 3 ? (
                              <TapAccount
                                   submitAccount={this.submitAccount}
                                   document_path={profile.document_path}
                                   language={language}
                                   profile={profile}
                                   handleChangeText={this.handleChange}
                                   inputValue={inputValue}
                                   checkInput={checkInput}
                                   handleSubmit={this.checkInput}
                                   data={bank_check ? upload_bookbank_other : upload_bookbank}
                                   menuItem={menuItem}
                              />
                         ) : tap_value === 4 ? (
                              <TapResetPassword
                                   language={language}
                                   data={textFiled.resetPassword}
                                   inputValue={inputValue}
                                   checkInput={checkInput}
                                   handleChange={this.handleChange}
                                   handleSubmit={this.checkInput}
                              />
                         ) : tap_value === 5 ? (
                              <TapCrypto
                                   language={language}
                                   handleChangeText={this.handleChange}
                                   inputValue={inputValue}
                                   checkInput={checkInput}
                                   data={textfield_crypto}
                                   menuItem={menuCrypto}
                                   handleSubmit={this.submitCrypto}
                                   profile={profile}
                              />
                         ) : (
                              <Tap2FA
                                   language={language}
                                   profile={profile}
                                   checked={checked2FA}
                                   handleChange={this.handleChange2FA}
                                   handleSubmit={this.submit2FA}
                              />
                         )}
                    </Grid>
               </Container>
          );
     }
}

export default withRouter(Profile);
