// const localhost = "http://localhost:4700";
const localhost = "https://portal.ats-forex.com";
const path = window.location.origin.includes("localhost") ? localhost : window.location.origin;
export const _ip = path;
export const ip = _ip + "/api/v1";

// export const ip_image = "http://localhost:4699/api/v1";
export const ip_image = "https://portal.qrsfx.com/api/v1";

export const GET = (path) => {
     // let language = JSON.parse(localStorage.getItem("language"));
     return new Promise((resolve, reject) => {
          fetch(ip + path, {
               method: "GET",
               headers: {
                    "Content-Type": "application/json",
                    // version: 1,
                    // farmid: 0,
               },
               credentials: "include",
          })
               .then((response) => response.json())
               .then((json) => resolve(json))
               .catch((err) => reject(err));
     });
};

export const POST = (path, obj, formData) => {
     let lang = localStorage.getItem("language");
     return new Promise((resolve, reject) => {
          fetch(ip + path, {
               method: "POST",
               headers: formData
                    ? {}
                    : {
                         "Content-Type": "application/json",
                    },
               body: formData ? obj : JSON.stringify({ ...obj, lang }),
               credentials: "include",
          })
               .then((response) => response.json())
               .then((json) => resolve(json))
               .catch((err) => reject(err));
     });
};

// export const GETIMAGE = (path, obj, formData, token) => {
//      let lang = "th";
//      return new Promise((resolve, reject) => {
//           fetch(ip_image + path, {
//                method: "POST",
//                headers: formData
//                     ?
//                     {
//                          authorization: token,
//                     }
//                     : {
//                          "Content-Type": "application/json",
//                     },
//                body: formData ? obj : JSON.stringify({ ...obj, lang }),
//                credentials: "include",
//           })
//                .then((response) => response)
//                .then((json) => resolve(json))
//                .catch((err) => reject(err));
//      });
// };

//AUTH
export const REGISTERAPI = "/auth/register";
export const LOGINAPI = "/auth/login";
export const RESENDEMAILAPI = "/auth/resend_verify";
export const VERIFYEMAILAPI = "/auth/verify";
export const CONTRATAPI = "/auth/congratulation";
export const CHECKSESSION = "/auth/checksession";
export const RESETPASSWORD = "/auth/reset_password";
export const FORGOTPASSWORD = "/auth/forgot_password";
export const RESETPASSWORDFORGOT = "/auth/reset_password_forgot";
export const LOGOUT = "/auth/logout";
export const REGISTERIB = "/auth/register_ib";

//PROFILE
export const PROFILEAPI = "/profile";
export const UPLOADPROFILEAPI = "/profile/upload_profile";
export const UPDATEPROFILEAPI = "/profile/update_profile";
export const UPDATEDOCUMENTAPI = "/profile/update_document";
export const UPDATEBANKAPI = "/profile/update_bank";
export const UPDATECRYPTOAPI = "/profile/update_usdt";
export const UPDATE2FAAPI = "/profile/update_2FA";

//FINANCIAL
export const PAYMENTAPI = "/payment";

//WALLET
export const WALLET = "/wallet";
export const DEPOSIT = "/wallet/deposit";
export const DEPOSITUPLOAD = "/wallet/upload_image";
export const WITHDRAW = "/wallet/withdraw";
export const OTP = "/wallet/otp";
export const TRANSACTIONDEPOSIT = "/wallet/get_transaction_id";
export const SLIPUSDTUPLOAD = "/wallet/upload_image_usdt";
export const GENERATEOTP2FA = "/wallet/generate_code";
export const VERIFYOTP2FA = "/wallet/verify_code";

//TRADING
export const GETTRADINGACCOUNT = "/mt4/info";
export const ADDTRADINGDEMO = "/mt4/create_demo";
export const CHANGEPASSWORD = "/mt4/change_password";
export const GETACCOUNTTYPE = "/mt4/account_type";
export const ADDTRADINGREAL = "/mt4/create";
export const DEPOSITMT4 = "/mt4/deposit";
export const WITHDRAWMT4 = "/mt4/withdraw";
export const GETTRANSACTIONMT4 = "/mt4/transaction";
export const CHANGELEVERAGE = "/mt4/change_leverage";
export const TRADINGTRANSACTION = "/mt4/trading_transaction";
export const DELETEACCOUNT = "/mt4/delete";
export const GETMT5ALL = "/mt4/account_type_mt5";
export const GETMT5TRANSACTION = "/mt4/mt5_transaction";
export const GETMT5TRANSACTIONWITHPAGE = "/mt4/mt5_transaction_with_page";

//IB
export const GETTRANSACTIONIB = "/ib/transaction";
export const TRANFERTOWALLET = "/ib/transfer_to_wallet";
export const GETIB = "/ib";
export const GETIBSUM = "/ib/ib_network"
export const GETIBCHILD = "/ib/ib_network_child"
export const GETCOUNTNETWORK = '/ib/count_network'
export const GETNEWCUSTOMER = "/ib/ib_new_customer"
export const GETPORT = "/ib/ib_port"
export const GETCOUNTNETWORKALL = '/ib/count_network_all'
export const GETIBHEADER = '/ib/get_ib_header'
export const GETIBALL = '/ib/get_ib_network_header'
export const GETIBALLNEST = '/ib/get_ib_network_nest'
export const GETIBSELECTNEST = '/ib/get_ib_select_nest'
export const GETALLIBNEST = '/ib/get_all_ib_network_nest'

//Cashback
export const GETTRANSACTIONCASHBACK = "/cashback/transaction";
export const TRANFERTOWALLETCASHBACK = "/cashback/transfer_to_wallet";

//SUPPORT
export const GETSUPPORT = "/support";
export const GETTYPESUPPORT = "/support/type";
export const ADDSUPPORT = "/support/add_ticket";

//PROMOTION
export const PROMOTIONAPI = "/promotion";
export const PROMOTIONBYIDAPI = "/promotion/by_id";
export const PROMOTIONSEARCH = "/promotion/search_promotion";
export const PROMOTIONGETIMG = "/promotion/image";


