import { Button, ButtonGroup, CardMedia, Container, Grid, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles, styled } from "@material-ui/styles";
import LoadingPage from "components/LoadingPage/LoadingPage";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import swal from "sweetalert2";
import logo_web from "../../../assets/logo/LOGOQrs.png";
import { CardDetail, GridTextField } from "../../../components/index";
import { textFiled } from "../../../functions/Static";
import { POST, REGISTERAPI } from "../../../service";
import GridPassword from "components/Gridcontainer/GridPassword";

const theme = createMuiTheme();

export class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: {},
            checkInput: {},
            step: 1,
            language_state: localStorage.getItem("language"),
            loading: false,
            showTopBtn: false,
            regex_: [
                { type: 1, th: "รหัสผ่านมีอย่างน้อย 6 ตัวอักษร", en: "Your password should have at least six characters", error_msg: false, name: "password" },
                { type: 2, th: "รหัสผ่านมีตัวอักษรพิมพ์เล็ก", en: "Your password should contain a lower case letter", error_msg: false, name: "password" },
                { type: 3, th: "รหัสผ่านมีตัวอักษรพิมพ์ใหญ่", en: "Your password should contain an upper case letter", error_msg: false, name: "password" },
                { type: 4, th: "รหัสผ่านมีตัวเลข", en: "Your password should contain a number", error_msg: false, name: "password" },
                { type: 0, th: "", en: "", error_msg: false, name: "confirm_password" },
                // { type: 2, th: "รหัสผ่านไม่ตรงกัน", en: "Passwords do not match.", correct_msg: false, name: "confirm_password" },
                // { type: 1, th: "รหัสผ่านมีอย่างน้อย 6 ตัวอักษร", en: "Your password should have at least six characters", error_msg: false, name: "confirm_password" },
                // { type: 2, th: "รหัสผ่านมีตัวอักษรพิมพ์เล็ก", en: "Your password should contain a lower case letter", error_msg: false, name: "confirm_password" },
                // { type: 3, th: "รหัสผ่านมีตัวอักษรพิมพ์ใหญ่", en: "Your password should contain an upper case letter", error_msg: false, name: "confirm_password" },
                // { type: 4, th: "รหัสผ่านมีตัวเลข", en: "Your password should contain a number", error_msg: false, name: "confirm_password" },
            ],
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
                this.setState({ showTopBtn: true });
            } else {
                this.setState({ showTopBtn: false });
            }
        });
    }
    goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    handleChange = (e) => {
        let { checkInput, inputValue } = this.state;
        let name = e.target.name;
        let value = e.target.value;
        checkInput[name] = {
            err: value ? false : true,
            label: "",
        };
        inputValue[name] = value;
        this.setState({
            inputValue,
            checkInput
        });
        this.checkInputAuto(name)
    };
    // checkInput = () => {
    //     let state = this.state;
    //     let { checkInput, step, language_state, inputValue } = this.state;
    //     let Check = textFiled[step === 1 ? "register" : "login"].map((el) => el.name);

    //     const emailRegexp =
    //         /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    //     const englistReg = /^[a-zA-Z]*$/;

    //     Check.forEach((e) => {
    //         if (state.inputValue[e]) {
    //             if (e === "name" && !(inputValue.name !== undefined && inputValue.name.match(englistReg))) {
    //                 checkInput[e] = {
    //                     err: true,
    //                     label: `${language_state === "th" ? "โปรดกรอกชื่อเป็นภาษาอังกฤษ" : "Please fill your name in English."}`,
    //                 };
    //             } else if (e === "lastname" && !(inputValue.lastname !== undefined && inputValue.lastname.match(englistReg))) {
    //                 checkInput[e] = {
    //                     err: true,
    //                     label: `${language_state === "th" ? "โปรดกรอกนามสกุลเป็นภาษาอังกฤษ" : "Please fill your lastname in English."}`,
    //                 };
    //             } else if (e === "username" && !(inputValue.username !== undefined && inputValue.username.match(emailRegexp))) {
    //                 checkInput[e] = {
    //                     err: true,
    //                     label: `${language_state === "th" ? "โปรดกรอกอีเมลล์ให้ถูกต้อง" : "Please fill your email correctly."}`,
    //                 };
    //             } else {
    //                 checkInput[e] = {
    //                     err: false,
    //                     label: "",
    //                 };
    //             }
    //         } else {
    //             checkInput[e] = {
    //                 err: true,
    //                 label: `${language_state === "th" ? "กรุณากรอกข้อมูลให้ครบ" : "Please fill complete"}`,
    //             };
    //         }
    //     });

    //     this.setState({ checkInput }, async () => {
    //         let { checkInput } = this.state;
    //         let input = Object.keys(checkInput).some((e) => checkInput[e].err);

    //         if (!input) {
    //             this.setState({ checkInput: {} });
    //             this.nextStep();
    //         } else {
    //             this.setState(checkInput);
    //         }
    //     });
    // };

    checkInputAuto = (name) => {
        let state = this.state;
        let { checkInput, language_state, inputValue, regex_ } = this.state;

        const emailRegexp =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const englistReg = /^[a-zA-Z]*$/;
        const passwordReg = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
        const lowercaseReg = /[a-z]/;
        const uppercaseReg = /[A-Z]/;
        const lengthReg = /.{6,}/;
        const numberReg = /\d/;
        // Trim spaces from input values
        if (typeof inputValue[name] === "string") {
            inputValue[name] = inputValue[name].replace(/\s+/g, "");
        }

        // console.log('e============>', name);
        if (state.inputValue[name]) {
            if (name === "name" && !(inputValue.name !== undefined && inputValue.name.match(englistReg))) {
                checkInput[name] = {
                    err: true,
                    label: `${language_state === "th" ? "โปรดกรอกชื่อเป็นภาษาอังกฤษ" : "Please fill your name in English."}`,
                };
            } else if (name === "lastname" && !(inputValue.lastname !== undefined && inputValue.lastname.match(englistReg))) {
                checkInput[name] = {
                    err: true,
                    label: `${language_state === "th" ? "โปรดกรอกนามสกุลเป็นภาษาอังกฤษ" : "Please fill your lastname in English."}`,
                };
            } else if (name === "username" && !(inputValue.username !== undefined && inputValue.username.match(emailRegexp))) {
                checkInput[name] = {
                    err: true,
                    label: `${language_state === "th" ? "โปรดกรอกอีเมลล์ให้ถูกต้อง" : "Please fill your email correctly."}`,
                };
            } /*else if ((name === "password" || name === "confirm_password") && !(inputValue.password !== undefined && inputValue.password.match(passwordReg))) {
                checkInput[name] = {
                    err: true,
                    label: `${language_state === "th" ? "รหัสผ่านอย่างน้อย 6 ตัว ตัวอักษรพิมพ์เล็กและตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว" : "Password. Min 6 symbols, at lowercase letter, at least 1 upper-case."}`,
                };
            }*/
            else if (name === "password") {

                let errorMessages = [];

                // Reusable function to handle each validation
                const validateField = (regEx, type, message) => {
                    let index = regex_.findIndex(e => e.type === type);

                    if (!regEx.test(inputValue[name])) {
                        errorMessages.push(message);
                        if (index !== -1) {
                            regex_[index].error_msg = true;
                        }
                    } else {
                        if (index !== -1) {
                            regex_[index].error_msg = false;
                        }
                    }

                    this.setState({ regex_ });
                };

                // Run validations
                validateField(lengthReg, 1, language_state === "th" ? "รหัสผ่านมีอย่างน้อย 6 ตัวอักษร" : "Your password should have at least six characters");
                validateField(lowercaseReg, 2, language_state === "th" ? "ต้องมีตัวอักษรพิมพ์เล็ก" : "Must contain at least one lowercase letter");
                validateField(uppercaseReg, 3, language_state === "th" ? "ต้องมีตัวอักษรพิมพ์ใหญ่" : "Must contain at least one uppercase letter");
                validateField(numberReg, 4, language_state === "th" ? "รหัสผ่านมีตัวเลข" : "Your password should contain a number");

                if (inputValue.confirm_password) {
                    let index = regex_.findIndex(e => e.type === 0);

                    if (index !== -1) {
                        regex_[index].th = '';
                        regex_[index].en = '';
                        regex_[index].error_msg = false;
                        this.setState({ regex_ });
                    }

                }

                if (errorMessages.length > 0) {
                    checkInput[name] = {
                        err: true,
                        // label: errorMessages.join(', '),
                    };
                } else {
                    checkInput[name] = {
                        err: false,
                        label: "",
                    };

                    const isValid = passwordReg.test(inputValue[name]);

                    if (isValid) {
                        textFiled.register.forEach((field) => {
                            if (field.name === name) {

                                regex_.forEach((rule) => {
                                    rule.error_msg = false;
                                });

                                this.setState({ regex_ });
                            }
                        });
                    }
                }
            } else if (name === "confirm_password") {

                let index = regex_.findIndex(e => e.type === 0);
                let isInvalid = regex_.filter(el => el.type !== 0).some(e => e.error_msg === true);
                const isMatch = inputValue.password === inputValue.confirm_password && !isInvalid;

                if (index !== -1) {
                    const updatedRegex = [...regex_];
                    if (isMatch) {
                        updatedRegex[index].th = 'รหัสผ่านตรงกัน';
                        updatedRegex[index].en = 'Passwords match.';
                        updatedRegex[index].error_msg = false;
                    } else {
                        updatedRegex[index].th = 'รหัสผ่านไม่ถูกต้อง';
                        updatedRegex[index].en = 'Password is incorrect.';
                        updatedRegex[index].error_msg = true;
                    }
                    // Update the state with the modified regex array
                    this.setState({ regex_: updatedRegex });
                }
            }
            else {
                checkInput[name] = {
                    err: false,
                    label: "",
                };
            }

        } else {
            if (name === "password" || name === "confirm_password") {
                checkInput[name] = {
                    err: false,
                    // label: `${language_state === "th" ? "รหัสผ่านอย่างน้อย 6 ตัว ตัวอักษรพิมพ์เล็กและตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว" : "Password. Min 6 symbols, at lowercase letter, at least 1 upper-case."}`,
                };

                let index = regex_.findIndex(e => e.type === 0);
                if (index !== -1) {
                    regex_[index].th = '';
                    regex_[index].en = '';
                    regex_[index].error_msg = false;
                    this.setState({ regex_ });
                }


            } /*else {
                checkInput[name] = {
                    err: true,
                    label: `${language_state === "th" ? "กรุณากรอกข้อมูลให้ครบ" : "Please fill complete"}`,
                };
            }*/
        }

        this.setState({ checkInput }, async () => {
            let { checkInput } = this.state;
            let input = Object.keys(checkInput).some((e) => checkInput[e].err);

            if (input) {
                this.setState(checkInput);
            }
        });
    };

    checkInput = () => {
        let state = this.state;
        let { checkInput, step, language_state, inputValue } = this.state;
        let Check = textFiled[step === 1 ? "register" : "login"].map((el) => el.name);

        const emailRegexp =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const englistReg = /^[a-zA-Z]*$/;
        const passwordReg = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

        // Trim spaces from input values
        Object.keys(inputValue).forEach((key) => {
            if (typeof inputValue[key] === "string") {
                inputValue[key] = inputValue[key].replace(/\s+/g, "");
            }
        });

        Check.forEach((e) => {

            if (state.inputValue[e]) {
                if (e === "name" && !(inputValue.name !== undefined && inputValue.name.match(englistReg))) {
                    checkInput[e] = {
                        err: true,
                        label: `${language_state === "th" ? "โปรดกรอกชื่อเป็นภาษาอังกฤษ" : "Please fill your name in English."}`,
                    };
                } else if (e === "lastname" && !(inputValue.lastname !== undefined && inputValue.lastname.match(englistReg))) {
                    checkInput[e] = {
                        err: true,
                        label: `${language_state === "th" ? "โปรดกรอกนามสกุลเป็นภาษาอังกฤษ" : "Please fill your lastname in English."}`,
                    };
                } else if (e === "username" && !(inputValue.username !== undefined && inputValue.username.match(emailRegexp))) {
                    checkInput[e] = {
                        err: true,
                        label: `${language_state === "th" ? "โปรดกรอกอีเมลล์ให้ถูกต้อง" : "Please fill your email correctly."}`,
                    };
                } else if ((e === "password") && !(inputValue.password !== undefined && inputValue.password.match(passwordReg))) {
                    checkInput[e] = {
                        err: true,
                        label: `${language_state === "th" ? "รหัสผ่านอย่างน้อย 6 ตัว ตัวอักษรพิมพ์เล็กและตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว" : "Password. Min 6 symbols, at lowercase letter, at least 1 upper-case."}`,
                    };
                }
                else if ((e === "confirm_password") && !(inputValue.confirm_password !== undefined && inputValue.confirm_password.match(passwordReg))) {
                    checkInput[e] = {
                        err: true,
                        label: `${language_state === "th" ? "รหัสผ่านอย่างน้อย 6 ตัว ตัวอักษรพิมพ์เล็กและตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว" : "Password. Min 6 symbols, at lowercase letter, at least 1 upper-case."}`,
                    };
                } else {
                    checkInput[e] = {
                        err: false,
                        label: "",
                    };
                }
            } else {
                checkInput[e] = {
                    err: true,
                    label: `${language_state === "th" ? "กรุณากรอกข้อมูลให้ครบ" : "Please fill complete"}`,
                };
            }
        });

        this.setState({ checkInput }, async () => {
            let { checkInput } = this.state;
            let input = Object.keys(checkInput).some((e) => checkInput[e].err);

            if (!input) {
                this.setState({ checkInput: {} });
                this.nextStep();
            } else {
                this.setState(checkInput);
            }
        });
    };

    nextStep = () => {
        let { inputValue, step, language_state } = this.state;
        if (step === 1) {
            if (inputValue.password === inputValue.confirm_password) {
                this.callAPIRegister();
            } else {
                swal.fire({
                    title: "Warning",
                    text: `${language_state === "th" ? "รหัสผ่านไม่ตรงกัน" : "Password not match"}`,
                    icon: "warning",
                    showConfirmButton: true,
                });
            }
        } else {
            this.setState({ step: 4, inputValue: {}, checkInput: {} });
        }
    };

    async callAPIRegister() {
        const { language_state } = this.state;
        try {
            let { inputValue, step } = this.state;
            let invite_email = this.props.match.params.invited_email;
            let manager_id = this.props.match.params.manager_id;
            // if (getallparams.invite_email) {
            //      invite_email = this.props.match.params.invited_email;
            // }
            if (manager_id != undefined) {
                invite_email = undefined;
            }
            if (inputValue.password.length < 6) {
                swal.fire({
                    title: `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                    text: `${language_state === "th"
                        ? "รหัสผ่านของคุณจำเป็นต้องมีอย่างน้อย 6 ตัวอักษรขึ้นไป"
                        : "Your password needs a minimum of six characters"
                        }`,
                    icon: "warning",
                    showConfirmButton: true,
                });
                this.setState({
                    error: true,
                    error_massage: `${language_state === "th"
                        ? "รหัสผ่านของคุณจำเป็นต้องมีอย่างน้อย 6 ตัวอักษรขึ้นไป"
                        : "Your password needs a minimum of six characters"
                        }`,
                });
            } else if (inputValue.password.search(/[a-z]/) < 0) {
                swal.fire({
                    title: `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                    text: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์เล็ก" : "Your password needs a lower case letter"}`,
                    icon: "warning",
                    showConfirmButton: true,
                });
                this.setState({
                    error: true,
                    error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์เล็ก" : "Your password needs a lower case letter"
                        }`,
                });
            } else if (inputValue.password.search(/[A-Z]/) < 0) {
                swal.fire({
                    title: `${language_state === "th" ? `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}` : "Warning"}`,
                    text: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์ใหญ่" : "Your password needs an uppser case letter"}`,
                    icon: "warning",
                    showConfirmButton: true,
                });
                this.setState({
                    error: true,
                    error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวอักษรพิมพ์ใหญ่" : "Your password needs an uppser case letter"
                        }`,
                });
            } else if (inputValue.password.search(/[0-9]/) < 0) {
                swal.fire({
                    title: `${language_state === "th" ? "ไม่สำเร็จ" : "Warning"}`,
                    text: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวเลข" : "Your password needs a number"}`,
                    icon: "warning",
                    showConfirmButton: true,
                });
                this.setState({
                    error: true,
                    error_massage: `${language_state === "th" ? "รหัสผ่านของคุณจำเป็นต้องมีตัวเลข" : "Your password needs a number"}`,
                });
            } else {
                this.setState({ loading: true });
                let register = await POST(REGISTERAPI, {
                    name: inputValue.name.replace(/\s/g, ""),
                    lastname: inputValue.lastname.replace(/\s/g, ""),
                    email: inputValue.username.replace(/\s/g, ""),
                    phone: inputValue.phone,
                    password: inputValue.password,
                    invite_email,
                    manager_id,
                });
                if (register.success) {
                    this.setState({ loading: false });
                    this.setState({ step: 2 });
                } else {
                    this.setState({ loading: false });
                    swal.fire({
                        title: "Warning",
                        text: register.message,
                        icon: "warning",
                        showConfirmButton: true,
                    });
                }
            }
        } catch (e) {
            this.setState({ loading: false });
            swal.fire({
                title: "Warning",
                text: e,
                icon: "warning",
                showConfirmButton: true,
            });
        }
    }

    handleClickLanguage = (language) => {
        localStorage.setItem("language", language === "th" ? "en" : "th");
        window.location.reload();
    };

    render() {
        const { inputValue, checkInput, step, loading, showTopBtn, regex_ } = this.state;
        const language = localStorage.getItem("language");
        const path = window.location.pathname.replace("/", "");
        return (
            <>
                <Container
                    maxWidth="sm"
                    sx={{
                        height: "100%",
                        minHeight: 888,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <LoadingPage open={loading} />
                    <Grid container rowSpacing={4}>
                        <Grid
                            sx={{
                                marginTop: "10px",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            item
                            xs={12}
                        >
                            <ButtonGroup size="small">
                                <ColorButtonEN language={language} onClick={() => this.handleClickLanguage(language)}>
                                    <Typography color={language === "en" ? "white" : ""}>EN</Typography>
                                </ColorButtonEN>
                                <ColorButtonTH language={language} onClick={() => this.handleClickLanguage(language)}>
                                    <Typography color={language === "th" ? "white" : ""}>TH</Typography>
                                </ColorButtonTH>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <LogoTitle language={language} step={step} path={path} />
                        </Grid>
                        <Grid item xs={12}>
                            {step === 1 ? (
                                <Grid container rowSpacing={3}>
                                    <GridTextField
                                        data={textFiled.register.filter((e) => e.type !== "password")}
                                        language={language}
                                        state={inputValue}
                                        check={checkInput}
                                        handleChange={this.handleChange}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter")
                                                this.checkInput();
                                        }}
                                    />
                                    <GridPassword
                                        data={textFiled.register.filter((e) => e.type === "password")}
                                        language={language}
                                        state={inputValue}
                                        check={checkInput}
                                        handleChange={this.handleChange}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter")
                                                this.checkInput();
                                        }}
                                        regex_={regex_}
                                    />
                                </Grid>
                            ) : step === 2 ? (
                                <Typography sx={{ color: "#000", fontSize: 28 }}>Please check your email and click to confirm your email.</Typography>
                            ) : step === 3 ? (
                                <Grid container rowSpacing={3}>
                                    <GridTextField
                                        data={textFiled.login}
                                        state={inputValue}
                                        check={checkInput}
                                        handleChange={this.handleChange}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                this.checkInput();
                                            }
                                        }}
                                    />
                                </Grid>
                            ) : (
                                <CardDetail />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container columnSpacing={step === 2 ? 1 : 0} rowSpacing={step === 2 ? 3 : 0}>
                                {step === 2 && (
                                    <Grid item xs={12} sm={6}>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            className="btn-style"
                                            sx={{ maxWidth: 260 }}
                                            onClick={() => this.setState({ step: 1 })}
                                        >
                                            {language === "th" ? "ย้อนกลับ" : "Previous"}
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={step !== 2 ? 12 : 6}>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        className="btn-style"
                                        style={{
                                            backgroundColor: "#4589C6",
                                        }}
                                        sx={{ maxWidth: 260 }}
                                        onClick={() => {
                                            let { step } = this.state;
                                            if (step === 1 || step === 3) this.checkInput();
                                            if (step === 2) this.props.history.push("/login");
                                            // if (step === 4) this.props.history.push("/dashboard");
                                        }}
                                    >
                                        {language === "th"
                                            ? step < 3
                                                ? "ถัดไป"
                                                : step > 3
                                                    ? "เข้าร่วม"
                                                    : "เข้าสู่ระบบ"
                                            : step < 3
                                                ? "next"
                                                : step > 3
                                                    ? "join"
                                                    : "login"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                {step === 1 && (
                    <Grid marginTop={{ xs: "-5%", sm: -1 }} marginRight={{ xs: 2, sm: "-45%" }} marginBottom={{ xs: 2 }} className="top-to-btm">
                        {" "}
                        {showTopBtn && (
                            <Grid>
                                <Button variant="outlined" color="error" className="btn-style icon-position icon-style" onClick={this.goToTop}>
                                    ▲
                                </Button>
                            </Grid>
                        )}{" "}
                    </Grid>
                )}
            </>
        );
    }
}

export default withRouter(Register);

const LogoTitle = ({ language, step, path }) => {
    const classes = useStyles();
    if (step < 4) {
        return (
            <Grid mb={4}>
                <Grid
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CardMedia className={classes.imgStyle} component="img" alt="Logo" image={logo_web} />
                    {/* <Typography className={classes.titleStyle}>QRS Broker</Typography> */}
                </Grid>
                <Typography className={classes.detailStyle}>
                    {language === "th"
                        ? // (path.includes('sale') ? "ลงทะเบียน IB Partner" :
                        "ลงทะเบียน"
                        : // path.includes('sale') ? "Register IB Partner" :
                        "Register"}
                </Typography>
            </Grid>
        );
    } else {
        return (
            <Grid mb={4}>
                <Typography className={classes.titleStyleStep}>Congratulations!!!</Typography>
            </Grid>
        );
    }
};

const ColorButtonEN = styled(Button)(({ language }) => ({
    backgroundColor: language === "en" ? "#56B3E5" : "#fff",
    "&:hover": {
        backgroundColor: language === "en" ? "#51A9D9" : "#fff",
    },
}));

const ColorButtonTH = styled(Button)(({ language }) => ({
    backgroundColor: language === "th" ? "#56B3E5" : "#fff",
    "&:hover": {
        backgroundColor: language === "th" ? "#51A9D9" : "#fff",
    },
}));

const useStyles = makeStyles(() => ({
    imgStyle: {
        height: 50,
        objectFit: "contain",
        width: "50%",
        marginRight: 8,
        [theme.breakpoints.up("sm")]: {
            height: 80,
        },
    },
    titleStyle: {
        color: "#fff",
        fontSize: 26,
        fontWeight: 600,
        [theme.breakpoints.up("sm")]: {
            fontSize: 50,
        },
    },
    detailStyle: {
        color: "#000",
        fontSize: 25,
        [theme.breakpoints.up("sm")]: {
            fontSize: 35,
        },
    },
    titleStyleStep: {
        color: "#000",
        fontSize: 30,
        fontWeight: 600,
        [theme.breakpoints.up("sm")]: {
            fontSize: 60,
        },
    },
    bgLang: {
        // backgroundColor: "#56B3E5",
    },
}));
