import { CardMedia, Grid, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
// import { PersonOutlineOutlined } from "@material-ui/icons";

const GridPassword = (props) => {

    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = (name) => {
        setShowPassword((prev) => ({ ...prev, [name]: !prev[name] }));
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    return props.data.map((e, i) => (
        <Grid
            key={i}
            item
            xs={e.xs || false}
            sm={e.sm || false}
            md={e.md || false}
            className={
                e.className ? (e.className === props.state[e.name] ? e.classNameGrid + " fonts-color" : e.classNameGrid) : e.classNameGrid || ""
            }
            container
            justifyContent="center"
            // alignItems="center"
        >
            {e.typography && (
                <Grid item sm={4} mt={2} textAlign="left" sx={{ display: { xs: "none", sm: "block" } }}>
                    <Typography>{(props.language === "th" ? e.typography_th || e.typography : e.typography) || ""}</Typography>
                </Grid>
            )}
            <Grid item xs={12} sm={e.typography ? 8 : false}>
                <TextField
                    disabled={e.disable ? e.disable : props.disabled}
                    error={props.check && props.check[e.name] ? props.check[e.name].err : false}
                    // helperText={
                    //     props.check && props.check[e.name] && props.check[e.name].err
                    //         ? props.check[e.name].label
                    //         : props.language === "th"
                    //             ? e.textPlaceholder_th
                    //                 ? e.textPlaceholder_th
                    //                 : ""
                    //             : e.textPlaceholder_en
                    //                 ? e.textPlaceholder_en
                    //                 : ""
                    // }
                    placeholder={(props.language === "th" ? e.placeholder_th || e.placeholder : e.placeholder) || ""}
                    className={e.classNameText || ""}
                    label={e.label || ""}
                    name={e.name || ""}
                    type={e.type === 'password' ? showPassword[e.name] ? 'text' : 'password' : e.type || ""}
                    value={props.state[e.name] || ""}
                    onChange={props.handleChange}
                    select={e.select}
                    fullWidth
                    variant="outlined"
                    onKeyDown={props.onKeyDown}
                    autoComplete="off"
                    InputProps={{
                        startAdornment: e.icon && (
                            <CardMedia
                                sx={{ height: 20, width: "auto", objectFit: "contain", mr: 2, mt: -0.25 }}
                                component="img"
                                alt="Logo"
                                image={e.icon}
                            />
                        ),
                        endAdornment: e.type === "password" && (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => handleClickShowPassword(e.name)}
                                    onMouseDown={handleMouseDownPassword}
                                    onMouseUp={handleMouseUpPassword}
                                    edge="end"
                                >
                                    {showPassword[e.name] ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {props.error_massage &&
                    <Typography className={classes.msgDetailError}>
                        {props.error_massage}
                    </Typography>
                }
                {props.regex_.map((reg) => {
                    const messageClass = (props.state[e.name] == null || props.state[e.name] === '')
                        ? classes.msgDetail
                        : reg.error_msg
                            ? classes.msgDetailError
                            : classes.msgDetailCorrect;

                    if (reg.name === e.name) {
                        return (
                            <Typography key={reg.type} className={messageClass}>
                                {props.language === "th"
                                    ? `${e.name === 'password' ? `•` : ""} ${reg.th}`
                                    : `${e.name === 'password' ? `•` : ""} ${reg.en}`
                                }
                            </Typography>
                        );
                    }

                    return null;
                })}
            </Grid>
        </Grid >
    ));
};

const useStyles = makeStyles((theme) => ({
    msgTitle: {
        fontSize: 14,
        color: "#676767",
        textAlign: "justify",
        marginTop: 5,
    },
    msgDetail: {
        fontSize: 12,
        color: "#676767",
        textAlign: "justify",
        marginTop: 5,
        textIndent: "15px"
    },
    msgDetailError: {
        fontSize: 12,
        color: "#e53935",
        textAlign: "justify",
        marginTop: 5,
        textIndent: "15px"
    },
    msgDetailCorrect: {
        fontSize: 12,
        color: "#4caf50",
        textAlign: "justify",
        marginTop: 5,
        textIndent: "15px"
    },
}));

// const regex_ = [
//     { th: "รหัสผ่านมีอย่างน้อย 6 ตัวอักษร", en: "Your password should have at least six characters", error_msg: false },
//     { th: "รหัสผ่านมีตัวอักษรพิมพ์เล็ก", en: "Your password should contain a lower case letter", error_msg: false },
//     { th: "รหัสผ่านมีตัวอักษรพิมพ์ใหญ่", en: "Your password should contain an upper case letter", error_msg: false },
//     { th: "รหัสผ่านมีตัวเลข", en: "Your password should contain a number", error_msg: false },
// ];

export default GridPassword;
